@import "grid-system.less";

* {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  padding: 0;
  margin: 0;
}

.container {
  width: 100%;
  padding-right: @spacing-xl;
  padding-left: @spacing-xl;

  @media (max-width: @bp-m-max) {
    padding-right: @spacing;
    padding-left: @spacing;
  }
}

.container-wrap {
  .container();

  max-width: @bp-xl-min;
  margin: 0 auto;
}

.page-content {
  padding-top: @spacing-l;
  padding-bottom: @spacing-l;
}

.section {
  padding-top: @spacing;
  padding-bottom: @spacing;

  .section-title:first-child {
    margin-top: 0;
  }
}

.section-1 {
  .section();

  padding: @spacing-l;
  background: white;
  border-radius: 20px;
  box-shadow: 0 5px 5px 0 fade(black, 5%);
}

._layout() {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0;
  margin: 0;

  @nav-items-spacing: @spacing-xs;

  .nav {
    display: inline-flex;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        display: inline-block;
        padding: 0 @nav-items-spacing;

        a,
        .btn-link {
          .link-s();
        }
      }
    }
  }

  .header {
    .container();
    .shadow();

    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: @spacing-xs;
    padding-bottom: @spacing-xs;
    background-color: @whitish;

    .logo-header {
      height: @spacing-l;
    }

    .nav {
      &:last-child {
        margin-right: -@nav-items-spacing;
      }
    }
  }

  .content {
    display: flex;
    flex: 1 0 auto;
    background-color: @whitish;
  }

  .footer {
    .container();

    padding-top: @spacing-xs;
    padding-bottom: @spacing-xs;
    text-align: center;
    background-color: @whitish;
    border-top: 1px solid fade(black, 3%);

    .copyright {
      .body-xs();

      color: @grey;
    }
  }
}

.auth-layout {
  ._layout();

  .content {
    .container();

    align-items: center;
    justify-content: center;
    padding-top: @spacing;
    padding-bottom: @spacing;
    background-image: url(../img/leaves.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: @bp-s-min;

    @media (max-width: @bp-s-max) {
      background-size: 98%;
    }
  }
}

.app-layout {
  ._layout();

  .main-nav {
    .container();
    .nav();

    padding-top: @spacing;
    padding-bottom: @spacing;
    background: @xxxx-light-grey;

    ul {
      margin-left: -@spacing-xs;
    }
  }

  .content {
    & > *:first-child {
      width: 100%;
    }
  }
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.inputs-row {
  display: flex;
  gap: 10px;
}

.radio-row {
  .body-s();

  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}
