.btn-1 {
  .body-xs();

  display: inline-block;
  padding: @spacing-xs @spacing;
  color: white;
  cursor: pointer;
  background: @dark-green;
  border: none;
  border-radius: 10px;
  box-shadow: 0 5px 5px -5px fade(black, 20%);

  &:hover {
    opacity: 0.7;
  }
}

.btn-danger {
  .btn-1();

  background: @red;

  &:hover {
    background: @dark-red;
  }
}

.link {
  .body();
  .body-strong();

  display: inline-block;
  padding: 0;
  color: @dark-green;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  transition: color 0.2s;

  &:hover {
    color: @light-green;
  }

  &.active {
    .body-strong();

    text-decoration: underline;
  }
}

.link-s {
  .link();
  .body-s();
}

.btn-link {
  .link();
  .body-xs();

  padding: @spacing-xxs @spacing;
}

.close-btn {
  .btn-1();

  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  padding: @spacing-xs;
  font-size: 14px;
  background: @xxx-light-grey;
  border: none;
  border-radius: 50%;
}

.btn-1 + .btn-link {
  display: block;
  margin-top: @spacing-xxs;
}
