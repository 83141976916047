/* FONTS */
.main-font {
  font-family: "Roboto", Helvetica, Arial, sans-serif;
}

/* WEIGHTS */
.thin {
  font-weight: 100;
}
.light {
  font-weight: 300;
}
.regular {
  font-weight: 400;
}
.semibold {
  font-weight: 500;
}
.bold {
  font-weight: 700;
}

/* TYPOGRAPHIC STYLES */
.heading {
  .main-font();
  .semibold();

  font-size: 1.4rem;
}
.body-xs {
  .body();

  font-size: 0.8rem;
}
.body-s {
  .body();

  font-size: 0.9rem;
}
.body {
  .main-font();
  .regular();

  font-size: 1rem;
  line-height: 1.1;
}
.body-strong() {
  .semibold();
}

/* SEMANTIC STYLES */
.section-title {
  .heading();

  margin: @spacing 0;
  color: @dark-green;
}

/* DEFAULT TYPOGRAPHIC STYLES */
html,
body {
  padding: 0;
  margin: 0;
  .main-font();
}
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  // .heading();
}
p {
  padding: 0;
  margin: 0.5em 0;
  .body();
}
strong {
  .body-strong();
}
::selection {
  color: white;
  background-color: @dark-green;
}

.kpi {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  font-size: 1.5rem;
  color: white;
  background: @dark-green;
  border-radius: 100%;

  &.--alt1 {
    background-color: @light-green;
  }

  &.--alt2 {
    background-color: @purple;
  }
}

.chip {
  .body-s();

  display: block;
  width: 100%;
  padding: @spacing-xxs @spacing;
  color: white;
  text-align: center;
  background: @dark-green;
  border-radius: 50px;

  &.--inline {
    display: inline-block;
    width: auto;
  }
}

.info {
  .body-s();

  padding: @spacing-xs @spacing-s;
  background: @xx-light-grey;
  border-radius: 10px;
}
