/* colors & textures mixins */
.glass-bg() {
  background: fade(@whitish, 70%);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

/* shadows mixins */
.shadow-s() {
  box-shadow: 0 8px 10px -9px rgba(0, 0, 0, 0.15);
}
.shadow() {
  box-shadow: 0 10px 15px -10px rgba(0, 0, 0, 0.15);
}
.shadow-m() {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
}
