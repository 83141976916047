/* alignment helpers */
.center {
  text-align: center;
}
.right {
  text-align: right;
}

.block {
  display: block;
  width: 100%;
}

/* responsive helpers */
.hidden-s-max {
  @media (max-width: @bp-s-max) {
    display: none;
  }
}
.hidden-md-max {
  @media (max-width: @bp-m-max) {
    display: none;
  }
}
.hidden-lg-max {
  @media (max-width: @bp-l-max) {
    display: none;
  }
}

/* miscellaneous helpers */
.nowrap() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.inline-div {
  display: inline-block;
  padding-right: @spacing-m;
  padding-bottom: @spacing-s;
}

.spacer {
  display: inline-block;
  width: @spacing;
  height: @spacing;
}

.margin-top {
  margin-top: @spacing;
}

.hidden {
  display: none;
}
