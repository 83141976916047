@grid-gutter: @spacing;
.grid,
.row {
  display: flex;
  flex-wrap: wrap;
  margin: -@grid-gutter / 2;

  & > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding: @grid-gutter / 2;
  }

  /* Mixin for columns */
  .col-generator(@division, @col-suffix: "", @index: 1)
    when
    not
    (@index = @division) {
    .col-@{index}-@{division} when (@col-suffix = "") {
      .col-fixed();

      width: 100% / @division* @index;
    }

    .col-@{col-suffix}-@{index}-@{division} when not (@col-suffix = "") {
      @min-width: "bp-@{col-suffix}-min";

      @media (min-width: @@min-width) {
        .col-fixed();

        width: 100% / @division* @index;
      }
    }

    .col-generator(@division, @col-suffix, @index + 1);
  }

  /* auto sizing columns */
  .col,
  .col-auto {
    flex: 1 0 0%;
  }
  .col-md {
    @media (min-width: @bp-m-min) {
      .col-auto();
    }
  }

  /* fixed sizes columns */
  .col-fixed {
    flex: 0 0 auto;
  }
  .col-0 {
    .col-fixed();

    width: 0%;
    padding: 0 !important;
  }
  .col-1-1 {
    .col-fixed();

    width: 100%;
  }

  .col-md-1-1 {
    @media (min-width: @bp-m-min) {
      .col-fixed();
      .col-1-1();
    }
  }

  /* Generated columns */
  .col-generator(3, m);
  .col-generator(3, m, 2);
  .col-generator(4, m);
  .col-generator(6, m);
}
.row {
  flex-wrap: nowrap;

  /* fitting column */
  .col-fit {
    flex: 0 0 auto;
    width: auto;
  }
}
.row-s {
  @media (min-width: @bp-s-min) {
    .row();
  }
}
.row-m {
  @media (min-width: @bp-m-min) {
    .row();
  }
  @media (max-width: @bp-s-max) {
    [class^="col"] {
      padding-top: @spacing-xs;
      padding-bottom: @spacing-xs;
    }
  }
}

.row-l {
  @media (min-width: @bp-xl-min) {
    .row();
  }
  @media (max-width: @bp-l-max) {
    [class^="col"] {
      padding-top: @spacing-xs;
      padding-bottom: @spacing-xs;
    }
  }
}

.table-grid {
  .grid();
  .body-s();

  width: 100%;
  margin-left: 0;

  [class^="btn"] {
    padding: @spacing-xxs @spacing-s;
    border-radius: 5px;

    & + [class^="btn"] {
      margin-left: @spacing-xs;
    }
  }

  .col-actions {
    margin-left: auto;
  }

  .table-grid-row {
    &:nth-child(2n + 1) {
      background-color: @xxxx-light-grey;
    }

    @media (min-width: @bp-m-min) {
      &:not(:first-of-type) {
        .input-label {
          display: none;
        }
      }

      label.input-label {
        padding-bottom: @spacing-xs;
      }
    }

    [class^="row"] {
      align-items: center;
    }

    @media (max-width: @bp-s-max) {
      [class^="col"] {
        padding-top: @spacing-xs;
        padding-bottom: @spacing-xs;
      }

      .col-actions {
        text-align: right;

        .btn-link {
          display: block;
          margin-bottom: @spacing-xs;
        }
      }
    }
  }
}
