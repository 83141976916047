.card {
  .shadow();

  padding: @spacing;
  background: white;
  border-radius: @spacing-xs;

  .card-head {
    padding: 0 0 @spacing 0;
    margin-top: -@spacing;
    border-bottom: 1px solid @xxx-light-grey;
  }

  .card-title {
    .section-title();

    margin: 0;
  }

  .card-body {
    padding: @spacing 0;
  }

  .card-footer {
    button {
      margin-right: @spacing-xs;
      margin-bottom: @spacing-m;
    }
  }

  &.--stretch {
    height: 100%;
  }
}

.card-glass {
  .card();
  .glass-bg();

  border: 1px solid fade(white, 40%);
}

.auth-card {
  .card-glass(); // répare, card-glass remplace

  width: 400px;
  max-width: calc(100vw - 2 * @spacing);
  padding: @spacing-xxl @spacing-xl @spacing-l;

  .input-label {
    color: @dark-green;
  }

  @media (max-width: @bp-xs-max) {
    padding: @spacing-xl;
  }
  @media (max-width: @bp-xxs-max) {
    padding-right: @spacing;
    padding-left: @spacing;
  }
}

.dialog {
  .card();
}
