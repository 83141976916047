@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.fade-in() {
  animation: fade-in 0.5s ease;
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale3d(0.5, 0.5, 1);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
.pop-in() {
  animation: pop-in 0.5s ease;
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.slide-up() {
  animation: slide-up 1s ease;
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.slide-down() {
  animation: slide-down 0.5s ease;
}

@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.slide-left() {
  animation: slide-left 0.5 ease;
}

@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.slide-right() {
  animation: slide-right 0.5 ease;
}
