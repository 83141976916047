.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  background: fade(@xxx-light-grey, 70%);
}
body.modal-open {
  height: 100vh;
  padding-right: 15px;
  overflow-y: hidden;

  .modal-overlay {
    overflow-y: auto;
  }
}
.dialog {
  .card();
  .slide-down();

  position: relative;
  width: 600px;
  max-width: 95%;
  padding: @spacing-xl @spacing-l;
  margin: 100px auto;
  background: white;
  border-radius: 5px;

  .close-btn {
    position: absolute;
    top: @spacing-s;
    right: @spacing-s;
  }

  @media (max-width: @bp-s-max) {
    margin-top: @spacing-l;
  }

  &.iframe-dialog {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-top: @spacing-xxl;
    margin: 0;
    background: fade(black, 70%);
    border-radius: 0;
  }
}
.dialog-l {
  width: 800px;

  @media (max-width: @bp-s-max) {
    padding-right: @spacing-s;
    padding-left: @spacing-s;
  }
}
.dialog-xl {
  width: 1000px;
  padding-bottom: @spacing-xxl;

  @media (max-width: @bp-s-max) {
    padding-right: @spacing-s;
    padding-left: @spacing-s;
  }
}
.dialog-head {
  padding: @spacing @spacing-l @spacing; // side spacing l to let room for close btn
  margin-bottom: @spacing-l;
  border-bottom: 1px solid @xx-light-grey;

  .center();
}
.dialog-body {
  margin-top: @spacing;
}
.dialog-footer {
  padding-top: @spacing-m;
  margin-top: @spacing-l;
  text-align: center;

  &:not(.no-sep) {
    border-top: 1px solid @xx-light-grey;
  }

  &.right {
    text-align: right;
  }

  button + button {
    margin-left: @spacing-s;
  }
}

.dialog-title {
  .center();

  color: @grey;
}

.dialog-create {
  .dialog-l();

  .center();
}
