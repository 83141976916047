.input {
  .body-xs();
  .block();

  padding: @spacing-xs @spacing;
  background: white;
  border: 1px solid @xxxx-light-grey;
  border-radius: 5px;
}

.select {
  .input();
}

.input-label {
  .body-xs();
  .body-strong();

  display: block;
  color: @dark-green;

  & + .input {
    margin-top: 5px;
  }
}

.label-bold {
  .input-label();

  font-size: 14px;
  font-weight: bold;
  color: @dark-green;
}

.form-block {
  & + & {
    margin-top: @spacing-s;
  }
}

.form-separation {
  padding-top: 20px;
  border-top: 1px solid @xxx-light-grey;
}

.input-tip {
  .body-xs();
  .right();

  margin-top: 0;
}

.input-inline {
  display: inline-block;
  width: 100px;
  padding: @spacing-xs;
  margin-right: @spacing-xs;
  margin-left: @spacing-xs;
}
