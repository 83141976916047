/* Generic */
@import "animations.less";
@import "variables.less";
@import "helpers.less";
@import "layout.less";
@import "colors.less";
@import "global-mixins";
@import "typo.less";
@import "buttons.less";
@import "forms.less";

/* Specific - Pages & components */
@import "cards.less";
@import "dialog.less";

/* to be dispatched in dedicated LESS files */
.form-errors {
  padding: 0 @spacing;
  color: white;
  list-style-type: none;
  background: @red;
  border-radius: 5px;
  .center();

  .body-xs();

  li + li {
    margin-top: @spacing-xxs;
  }

  li:first-child {
    padding: @spacing-s 0 0 0;
  }
  li:last-child {
    padding: 0 0 @spacing-s 0;
  }
}

.profile-display {
  .body-s();

  strong {
    color: @dark-green;
  }

  & > div + div {
    margin-top: @spacing;
  }
}

.hidden {
  display: none;
}

.privacy-page {
  padding: @spacing;
  background: fade(white, 80%);

  h1 {
    color: @light-green;
  }

  h2 {
    color: @dark-green;
  }

  section {
    padding: @spacing 0;
  }

  table {
    tr {
      background: fade(@xxx-light-grey, 50%);
    }
    th,
    td {
      padding: 5px 10px;
    }
    th {
      background: @light-green;
    }
  }
}
